import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

// Components
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

// Pages - Authentication
import Login from 'pages/auth/Login';
import LoginRedirect from 'pages/auth/LoginRedirect';
import RequireAuth from 'pages/auth/RequireAuth';
import RequireAlphaSignup from 'pages/auth/RequireAlphaSignup';

// Pages - Payments
import PaymentHistory from 'pages/payment/PaymentHistory';

// Pages - Markteplace
import MarketplaceHome from 'pages/marketplace/MarketplaceHome';

// Pages - Billing
import BetaSignUpPage from 'pages/billing/BetaSignUpPage';
import StripePayment from 'pages/billing/StripePayment';

// Pages - Deployments
import CreateAppchain from 'pages/deployments/CreateAppchain';
import KatanaAppDetail from 'pages/deployments/KatanaAppDetail';
import MadaraAppDetail from 'pages/deployments/MadaraAppDetail';
import LaunchAppchain from 'pages/deployments/LaunchAppchain';
import ProjectDetail from 'pages/deployments/ProjectDetail';
import ProjectsPage from 'pages/deployments/ProjectsPage';
import ProjectSettings from 'pages/deployments/ProjectSettings';

// Pages - Monitoring
import DisplayServiceLogs from 'pages/monitoring/DisplayServiceLogs';
import Monitor from 'pages/monitoring/Monitor';

// Pages - Privacy
import PrivacyPolicy from 'pages/privacy/PrivacyPolicy';
import TermsOfService from 'pages/privacy/TermsOfService';

// Pages - General
import Support from 'pages/Support';
import Teams from 'pages/Teams';
import Explore from 'pages/explore/ExploreProjects';

Modal.setAppElement('#root');

function MainRoutes() {

  return (
    <>
      <Navbar />
      <RequireAuth>
        <LoginRedirect />
        <Routes>
          <Route element={<RequireAlphaSignup/>}>
            <Route path="/" element={<ProjectsPage />} />
            <Route path="/teams/*" element={<Teams />} />
            <Route path="/create-appchain" element={<CreateAppchain />} />
            <Route path="/launch-appchain" element={<LaunchAppchain />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/monitor" element={<Monitor />} />
            <Route path="/support" element={<Support />} />
            {/* <Route path="/payment-history" element={<PaymentHistory />} /> */}
            {/* <Route path="/payment" element={<StarkPayment><Payment/></StarkPayment>}/> */}
            <Route path="/projects/:projectId" element={<ProjectDetail/>}/>
            <Route path="/projects/:projectId/katana/:katanaId" element={<KatanaAppDetail/>}/>
            <Route path="/projects/:projectId/madara/:katanaId" element={<MadaraAppDetail/>}/>
            <Route path="/pay-with-stripe/:plan" element={<StripePayment/>}/>
            <Route path="/projects/:projectId/:serviceName/:serviceId/logs" element={<DisplayServiceLogs/>}/>
            <Route path="/projects/settings/:projectId" element={<ProjectSettings/>}/>
            <Route path="/marketplace" element={<MarketplaceHome />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/alpha-signup" element={<BetaSignUpPage />} />
          
        </Routes>
      </RequireAuth>
      <Footer />
    </>
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
      {location.pathname === '/login' ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <div className="pt-16">
          <MainRoutes />
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;