import { applyMixin } from "core/reducers/applyMixin";
import { BaseService } from "services/core/base";
import { DeployableServiceMixin } from "./deployableMixin";
import { FaucetMixin } from "./faucetMixin";


class ProjectAnvilService extends BaseService{
    baseUri = "/deployments/projects/<projectId>/anvil/";

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }
}

export const projectAnvilService = new ProjectAnvilService();
applyMixin(projectAnvilService, DeployableServiceMixin);
applyMixin(projectAnvilService, FaucetMixin);

export default projectAnvilService;
