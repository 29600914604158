import ToriiLogo from "../imgs/tools/torii.svg";
import quasmLogo from "../imgs/quasm_trans.png";

import AdditionalService from "components/deployments/services/AdditionalService";

export default function StarknetServices({selectedServices, handleServiceToggle}){

    return (
        <div>
            <h5 className="text-white text-normal font-thin mb-2">Deploy additional services</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <AdditionalService
                    logo={quasmLogo}
                    name="Custom Block Explorer"
                    className="bg-[#0F0F2D]"
                    tooltip="Block Explorer service is used to track all transactions on your appchain, customized with your brand and logo"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
                <AdditionalService
                    logo={quasmLogo}
                    name="Token Bridge"
                    className="bg-[#0F0F2D]"
                    tooltip="Natively Bridge tokens from the Ethereum blockchain"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
                <AdditionalService
                    logo={ToriiLogo}
                    name="Torii"
                    tooltip="Torii service is used to index data on your Dojo world providing graphql endpoints"
                    checked={selectedServices.torii}
                    onChange={() => handleServiceToggle('torii')}
                />
                <AdditionalService
                    logo={quasmLogo}
                    name="Authorization service"
                    className="bg-[#0F0F2D]"
                    tooltip="Require an API Token in order to interact with the RPC URL"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
                <AdditionalService
                    logo={ToriiLogo}
                    name="Quasm Wallet"
                    tooltip="Custom Session Key Wallet"
                    checked={selectedServices.torii}
                    onChange={() => handleServiceToggle('torii')}
                />
                <AdditionalService
                    logo={ToriiLogo}
                    name="Argent Wallet"
                    tooltip="Use the Argent Wallet on your appchain"
                    checked={selectedServices.torii}
                    onChange={() => handleServiceToggle('torii')}
                />
                <AdditionalService
                    logo={quasmLogo}
                    name="Braavos Wallet"
                    className="bg-[#0F0F2D]"
                    tooltip="Use the Braavos Wallet on your appchain"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
            </div>
            <p className="mt-4 text-sm text-gray-400">
            🚀 More services coming soon...
        </p>
    </div>
    )
}