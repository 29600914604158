import { BaseService } from "../core/base";

class ProjectService extends BaseService {

  baseUri = "/deployments/projects/";

  async create(name, visibility=null) {
    const resp = await this.apiClient.post(
      this.baseUri, { name: name , visibility: visibility}
    )
    return resp.data;
  }

  async list(name = null, isArchived = false) {
    let finalUri = isArchived
      ? name == null ? this.baseUri : this.baseUri + "?name=" + name + "&is_archived=true"
      : name == null ? this.baseUri : this.baseUri + "?name=" + name
    const response = await this.apiClient.get(finalUri)
    return response.data;
  }

  async retrieve(projectId) {
    const response = await this.apiClient.get(
      this.baseUri + projectId + "/"
    )
    return response.data;
  }

  async update(projectId, data){
    const response = await this.apiClient.patch(
        this.baseUri + projectId + "/", data
    );
    return response.data
  }

  async delete(projectId) {
    const response = await this.apiClient.delete(
      this.baseUri + projectId + "/"
    )
    return response.data;
  }

  async archive(projectId) {
    const response = await this.apiClient.post(
      this.baseUri + projectId + "/archive/"
    )
    return response.data;
  }

  async listAppchains(projectId) {
    const response = await this.apiClient.get(
      this.baseUri + projectId + "/appchains"
    )
    return response.data;
  }

  async inviteUsers(projectId, email){
    const response = await this.apiClient.post(
        this.baseUri + projectId + "/users/", {users: [email]}
    )
    return response.data;
  }

  async getVisibilityStatus(){
    const response = await this.apiClient.get("/refs/project-visibility-status");
    return response.data;
  }

  async getExplorableProjects(){
    const response = await this.apiClient.get("/deployments/explorable-projects/");
    return response.data;
  }

  async createAnvil(data){
    const response = await this.apiClient.post(
        "deployments/anvil", data
    )
    return response.data;
  }

  async createKatana(data){
    const response = await this.apiClient.post(
        "deployments/katana", data
    )
    return response.data;
  }

  async createMadara(data){
    const response = await this.apiClient.post(
        "deployments/madara", data
    )
    return response.data;
  }

  async createAppchain(framework, data){
    if (framework === "anvil") return this.createAnvil(data);
    if (framework === "katana") return this.createKatana(data);
    if (framework === "madara") return this.createMadara(data);
    if (framework === "authenticated_madara"){
      data.is_authenticated = true;
      return this.createMadara(data);
    }
    throw new Error("framework does not exists");
  }

  async addLogo(projectId, logo) {
    const formData = new FormData();
    formData.append('file', logo);
    formData.append('type', 0);
    const response = await this.apiClient.post(
        `${this.baseUri}${projectId}/files`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.data;
  }
}


export const projectService = new ProjectService();

export default projectService;