import React from "react";


const handleCopy = (val) => {
    navigator.clipboard.writeText(val);
  };

export default function WalletIntegration({appChains}){

    return (
        <div className="space-y-6">
            <div className="bg-[#0B0B20] p-6 rounded-lg">
            <label className="block text-sm font-medium">API Key</label>
            <div className="mt-2 flex items-center space-x-4">
                <input
                type="text"
                value={appChains[0].quasm_wallet_api_key}
                readOnly
                className="flex-grow bg-[#020215] border border-gray-600 p-2 rounded-md text-white"
                />
                <button className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
                onClick={()=>handleCopy(appChains[0].quasm_wallet_api_key)}
                >
                Copy
                </button>
            </div>
            </div>
            <div className="bg-[#0B0B20] p-6 rounded-lg">
            <label className="block text-sm font-medium">Allowed Domains</label>
            <div className="mt-4 space-y-4">
                {/* Replace with dynamic data */}
                <div className="flex justify-between items-center bg-[#020215] p-4 rounded-md">
                <p>example.com</p>
                <button className="text-red-400 hover:underline">Remove</button>
                </div>
                <div className="flex space-x-4">
                <input
                    type="text"
                    placeholder="Add a domain"
                    className="flex-grow bg-[#020215] border border-gray-600 p-2 rounded-md text-white"
                />
                <button className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md">
                    Add
                </button>
                </div>
            </div>
            </div>
        </div>
    )
};