import ArbitrumNitroLogo from "../imgs/icons/ArbitrumNitro.png";
import AztecLogo from "../imgs/icons/Aztec.png";
import OptimismLogo from "../imgs/icons/Optimism.png";
import MadaraLogo from "../imgs/icons/madara.png";
import ScrollLogo from "../imgs/icons/Scroll.jpg";
import StacksLogo from "../imgs/icons/Stacks.png";
import ZKSyncLogo from "../imgs/icons/ZKSync.png";
import EthereumLogo from "../imgs/ethereum-eth-logo.svg"


const appchainFrameworkData = [
    {
        text: "Madara",
        logo: MadaraLogo,
        frameworkKey: "madara",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Anvil",
        logo: EthereumLogo,
        frameworkKey: "anvil",
        tooltipText:  "EVM Tranasction Sequencer"
    },
    {
        text: "Arbitrum",
        logo: ArbitrumNitroLogo,
        frameworkKey: "arbitrum",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Optimism",
        logo: OptimismLogo,
        frameworkKey: "optimism",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Zk Sync",
        logo: ZKSyncLogo,
        frameworkKey: "zksync",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Scroll",
        logo: ScrollLogo,
        frameworkKey: "scroll",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Aztec",
        logo: AztecLogo,
        frameworkKey: "aztec",
        tooltipText:  "Powered by the Madara Alliance"
    },
    {
        text: "Stacks",
        logo: StacksLogo,
        frameworkKey: "stacks",
        tooltipText:  "Powered by the Madara Alliance"
    }
]

export default appchainFrameworkData