import React, {useState} from "react"
import { isValidEmail } from "../../../core/validators/index";
import { toast } from "react-toastify";

import projectService from "services/deployments/project";

export default function TeamManagement({project, fetchProject}) {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleInvite = async () => {
      if (!isValidEmail(email)) {
        setError('Please enter valid email addresses.');
        return;
      }
      setIsLoading(true);
      try {
        await projectService.inviteUsers(project.id, email);
        setEmail('');
        setError(null);
        fetchProject()
        toast.success('People have been invited successfully');
      } catch (err) {
        console.log(err)
        setError('An error occurred while sending invitations.');
        toast.error('Failed to send invitations.');
      } finally {
        setIsLoading(false);
      }
    };

    return (
        <div className="space-y-6">
            <div className="bg-[#0B0B20] p-6 rounded-lg">
            <label className="block text-sm font-medium">Invite User</label>
            <div className="mt-2 flex space-x-4">
                <input
                    type="email"
                    placeholder="Enter email"
                    className="flex-grow bg-[#020215] border border-gray-600 p-2 rounded-md text-white"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <select className="bg-[#020215] border border-gray-600 p-2 rounded-md text-white">
                <option>Admin</option>
                <option>Editor</option>
                <option>Viewer</option>
                </select>
                    <button 
                        onClick={handleInvite}
                        className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md">
                    Invite
                </button>
                {error && <p className="text-red-500 bg-red-100/10 p-2 rounded mb-4">{error}</p>}
            </div>
            </div>
            <div className="bg-[#0B0B20] p-6 rounded-lg">
            <h3 className="text-lg font-medium">Team Members</h3>
            <ul className="mt-4 space-y-4">
                {/* Replace with dynamic data */}
                {
                    project.users.map(user => (
                        <li key={user.id} className="flex justify-between items-center bg-[#020215] p-4 rounded-md">
                        <div>
                            <p className="font-medium">{user.email}</p>
                            <p className="text-sm text-gray-400">Admin</p>
                        </div>
                        <div className="flex space-x-4">
                            <button className="text-blue-400 hover:underline">Change Role</button>
                            <button className="text-red-400 hover:underline">Remove</button>
                        </div>
                        </li>
                    ))
                }
            </ul>
            </div>
        </div>
    )
};