import { useState, useEffect, useCallback } from 'react';
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory";
import { jobService } from "../../services/jobs/job";


const useLogData = (appchain) => {

    const [logEntries, setLogEntries] = useState([]);
    const [isReloading, setIsReloading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [ autoRefresh, setAutoRefresh ] = useState(false)

    const fetchLogs = useCallback(async () => {
        setIsReloading(true);
        try {
            let result = await getAppchainService(appchain.appchain_type).getLogs(appchain.project, appchain.id, searchQuery);
            let tries = 0;
            let jobResult = await jobService.getTaskResult(result.task_id);
            while (tries < 5 && jobResult.status === "PENDING") {
                tries++;
                await new Promise(resolve => setTimeout(resolve, 1000));
                jobResult = await jobService.getTaskResult(result.task_id);
            }
            const data = jobResult.result ? jobResult.result : { log_content: "Error fetching logs ... Ask Support" };
            setLogEntries(parseLogData(data.log_content));
        } catch (error) {
            console.error("Error fetching logs:", error);
            setLogEntries(["Error fetching logs ... Ask Support"]);
        } finally {
            setIsReloading(false);
        }
    }, [appchain]);

    const parseLogData = (data) => {
        if (!data) {
            return [];
        }
        return data.split("\n").filter((line) => line.trim() !== "");
    };

    useEffect(() => {
        if (appchain && appchain.is_deployed){
            fetchLogs();
        }
        const interval = setInterval(() => {
            if (autoRefresh) {
                fetchLogs();
            }
        }, 8000);
        return () => clearInterval(interval);

    }, [autoRefresh]);

    const handleReload = () => {
        if (appchain && appchain.is_deployed){
            setLogEntries(["Fetching new logs"])
            fetchLogs();
        }
    };

    return { logEntries, isReloading, handleReload, setSearchQuery, searchQuery, autoRefresh, setAutoRefresh };
};

export default useLogData;