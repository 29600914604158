import { Switch } from '@headlessui/react';
import { Tooltip } from "components/deployments/LaunchInputs";
import Info from "../../../imgs/icons/Info.svg";

const AdditionalService = ({ logo, name, tooltip, checked, onChange }) => (
    <div className="flex justify-between items-center bg-[#0F0F2D] p-4 rounded-lg border border-white/10">
        <div className="flex items-center space-x-3">
            <img src={logo} alt={`${name} Logo`} className="h-6 w-6" />
            <span className="text-white text-sm">{name}</span>
            <Tooltip icon={Info} text={tooltip} />
        </div>
        <Switch
            checked={checked}
            onChange={onChange}
            className={`${checked ? 'bg-blue-500' : 'bg-gray-400'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
        >
            <span className={`${checked ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out`} />
        </Switch>
    </div>
);

export default AdditionalService;