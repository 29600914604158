import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Info from "../../imgs/icons/Info.svg";
import { FaEdit, FaCheckCircle } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';

import useLaunchAppchain from 'hooks/deployments/uselaunchappchain';
import KatanaSpecs from 'components/deployments/KatanaSpecs';
import MadaraSpecs from 'components/deployments/MadaraSpecs';
import { Button, Tooltip } from "components/deployments/LaunchInputs";
import EthereumServices from "containers/EthereumServices";
import StarknetServices from 'containers/StarknetServices';

import appchainFrameworkData from ' data/appchainFrameworkData';



export default function LaunchAppchain() {
    const {
        selectedFramework, projectName, setProjectName,
        setProjectVisibility, projectVisibilities, metaData, setMetaData,
        error, setError,
        summaryItems, handleSubmit, selectedServices, setSelectedServices, isSubmitting,
        changeAppchainFramework
    } = useLaunchAppchain();

    const [isHovered, setHovered] = useState(false);
    const [validProjectName, setValidProjectName] = useState(null);

    const validateProjectName = (name) => {
        const isValid = name.length >= 3;
        setValidProjectName(isValid);
        return isValid;
    };

    const handleProjectNameChange = (e) => {
        const name = e.target.value;
        setProjectName(name);
        validateProjectName(name);
    };

    const handleServiceToggle = (service) => {
        setSelectedServices(prev => ({ ...prev, [service]: !prev[service] }));
    };

    return (
        <div className="container mx-auto px-4 py-8 relative">
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">
                    <SectionTitle title="Build Your Appchain" />
                    <div className="bg-[#0A0A22] rounded-lg p-6 border border-white/15">
                        <ProjectNameInput
                            projectName={projectName}
                            onChange={handleProjectNameChange}
                            isValid={validProjectName}
                        />
                        <VisibilitySelector
                            visibilities={projectVisibilities}
                            onChange={setProjectVisibility}
                        />
                        <FrameworkButtons
                            frameworks={appchainFrameworkData}
                            selectedFramework={selectedFramework}
                            onFrameworkChange={changeAppchainFramework}
                        />
                        {error?.metadata && <ErrorMessage message={error.metadata} />}
                        <SpecsSection
                            selectedFramework={selectedFramework}
                            metaData={metaData}
                            setMetaData={setMetaData}
                        />
                        <ServiceSection
                            selectedFramework={selectedFramework}
                            selectedServices={selectedServices}
                            onServiceToggle={handleServiceToggle}
                        />
                    </div>
                </div>
                <SummarySection
                    summaryItems={summaryItems}
                    validProjectName={validProjectName}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    isHovered={isHovered}
                    setHovered={setHovered}
                />
            </div>
            {error?.permissionDenied && <AlphaSignupModal setError={setError} />}
        </div>
    );
}

const SectionTitle = ({ title }) => (
    <h2 className="text-2xl text-white mb-6">{title}</h2>
);

const ProjectNameInput = ({ projectName, onChange, isValid }) => (
    <div className="mb-6">
        <label htmlFor="projectName" className="block text-normal font-thin text-white mb-2">
            Name your project
        </label>
        <div className="relative">
            <input
                id="projectName"
                placeholder="Project Name"
                onChange={onChange}
                className={`bg-[#0F0F2D] border ${isValid === false ? 'border-red-500' : 'border-white/10'} text-white w-full p-3 pr-10 rounded-md focus:ring-blue-500`}
                defaultValue={projectName}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaEdit className="text-white" />
            </span>
        </div>
        {isValid === false && <p className="text-red-500 text-sm mt-1">Project name is too short.</p>}
    </div>
);

const VisibilitySelector = ({ visibilities, onChange }) => (
    <div className="mb-6">
        <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
            Choose the visibility of your project
        </label>
        <select
            id="visibility"
            onChange={(e) => onChange(e.target.value)}
            className="w-full p-3 bg-[#0F0F2D] text-white border border-white/10 rounded-md"
        >
            {visibilities.map(({ label, value }) => (
                <option key={value} value={value}>{label}</option>
            ))}
        </select>
    </div>
);

const FrameworkButtons = ({ frameworks, selectedFramework, onFrameworkChange }) => (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
        {frameworks.map(({ text, logo, frameworkKey, tooltipText }) => (
            <Button
                key={frameworkKey}
                text={text}
                logo={logo}
                onClick={() => onFrameworkChange(frameworkKey)}
                className={`text-sm w-full ${selectedFramework === frameworkKey ? "border-white/80" : "border-white/10"}`}
            >
                <Tooltip icon={Info} text={tooltipText} />
            </Button>
        ))}
    </div>
);

const SpecsSection = ({ selectedFramework, metaData, setMetaData }) => (
    ["madara", "authenticated_madara"].includes(selectedFramework)
        ? <MadaraSpecs metaData={metaData} setMetaData={setMetaData} />
        : <KatanaSpecs metaData={metaData} setMetaData={setMetaData} />
);

const ServiceSection = ({ selectedFramework, selectedServices, onServiceToggle }) => (
    selectedFramework === "anvil"
        ? <EthereumServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle} />
        : <StarknetServices selectedServices={selectedServices} handleServiceToggle={onServiceToggle} />
);

const ErrorMessage = ({ message }) => (
    <div className="bg-red-500 text-white p-4 rounded-md mb-4">
        <p>{message}</p>
    </div>
);

const SummarySection = ({
    summaryItems,
    validProjectName,
    isSubmitting,
    handleSubmit,
    isHovered,
    setHovered,
}) => (
    <div className="lg:col-span-1">
        <h2 className="text-2xl text-white mb-6">Summary</h2>
        <div className="bg-[#0A0A22] border border-gray-700 rounded-lg p-6">
            <h4 className="text-white mb-4 text-xl">Your Appchain</h4>
            <AnimatePresence>
                {summaryItems.map((summary, idx) => (
                    <motion.div
                        key={idx}
                        initial={{ opacity: 0, filter: "blur(4px)" }}
                        animate={{ opacity: 1, filter: "blur(0px)" }}
                        exit={{ opacity: 0, filter: "blur(4px)" }}
                        className="flex items-center mb-3 pb-3 border-b border-white/15"
                    >
                        <FaCheckCircle className="text-white mr-3" />
                        <p className="text-white/80 font-thin">{summary}</p>
                    </motion.div>
                ))}
            </AnimatePresence>
            <h5 className="text-xl mt-6 mb-4 text-white">Costs and Pricing</h5>
            {[
                { label: "Deployment Cost", value: "Free" },
                { label: "Plan Cost", value: "Free" },
                { label: "Your Savings", value: "34 hours" },
            ].map(({ label, value }, idx) => (
                <div
                    key={idx}
                    className="flex justify-between mb-3 pb-2 border-b border-gray-600"
                >
                    <p className="text-[#6C6C7A]">{label}</p>
                    <p className="text-white">{value}</p>
                </div>
            ))}
            <div className="relative mt-6">
                <button
                    className={`w-full py-2 rounded ${
                        !validProjectName
                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                            : "bg-white text-black hover:bg-gray-800 hover:text-white"
                    }`}
                    onClick={handleSubmit}
                    disabled={!validProjectName || isSubmitting}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    Launch Your Appchain
                </button>
                {!validProjectName && isHovered && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-max p-2 text-xs text-black bg-white rounded-lg shadow-lg z-10">
                        Enter a project name
                    </div>
                )}
            </div>
        </div>
    </div>
);


const AlphaSignupModal = ({ setError }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center relative">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Join Our Exclusive Alpha Program!</h2>
            <p className="mb-6 text-gray-600">
                Get early access to enterprise features and help shape the future of our product.
            </p>
            <Link
                to="/alpha-signup"
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold py-3 px-6 rounded-lg shadow-md transition-colors duration-200"
            >
                Join the Alpha Program Now
            </Link>
            <button
                onClick={() => setError(prev => ({ ...prev, permissionDenied: false }))}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-label="Close"
            >
                ✕
            </button>
        </div>
    </div>
);