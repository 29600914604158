import quasmLogo from "../imgs/quasm_trans.png";
import { Switch } from '@headlessui/react';
import { Tooltip } from "components/deployments/LaunchInputs";
import Info from "../imgs/icons/Info.svg";

const AdditionalService = ({ logo, name, tooltip, checked, onChange }) => (
    <div className="flex justify-between items-center bg-[#0F0F2D] p-4 rounded-lg border border-white/10">
        <div className="flex items-center space-x-3">
            <img src={logo} alt={`${name} Logo`} className="h-6 w-6" />
            <span className="text-white text-sm">{name}</span>
            <Tooltip icon={Info} text={tooltip} />
        </div>
        <Switch
            checked={checked}
            onChange={onChange}
            className={`${checked ? 'bg-blue-500' : 'bg-gray-400'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
        >
            <span className={`${checked ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out`} />
        </Switch>
    </div>
);


export default function EthereumServices({selectedServices, handleServiceToggle}){

    return (
        <div>
            <h5 className="text-white text-normal font-thin mb-2">Deploy additional services</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <AdditionalService
                    logo={quasmLogo}
                    name="Block Scout"
                    className="bg-[#0F0F2D]"
                    tooltip="Block Explorer service is used to track all transactions on your appchain, customized with your brand and logo"
                    checked={selectedServices.blockExplorer}
                    onChange={() => handleServiceToggle('blockExplorer')}
                />
            </div>
            <p className="mt-4 text-sm text-gray-400">
            🚀 More services coming soon...
        </p>
    </div>
    )
}